//
// Footer Styles
// --------------------------------------------------

body.page-template-template-homepage,
body.page-template-teamplate-team {
	
	#footer {
		background-color: $color-gray-light;
	}
}

body.page-template-template-references {
	
	#cta-footer {

		.wrapper {
			padding-inline: 0;
		}
	}
}

#footer {
	font-size: $small;
	line-height: normal;
	
	a {
		color: inherit;
	}
	
	#footer-top {
		color: #ffffff;
		border-radius: .3rem;
		overflow: hidden;
		padding-inline: 3.4rem;
		padding-top: 5.3rem;
		padding-bottom: 2.8rem;
		position: relative;

		@media (max-width:767px) {
			padding-top: 3rem;
			padding-bottom: 1.5rem;
			padding-inline: 2rem;
		}

		.footer-bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.top-part {
			position: relative;

			.logo {
				max-width: 15rem;
				height: auto;

				svg {
					width: 100%;
					height: auto;
				}
			}

			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 4rem;

				@media (max-width:576px) {
					flex-direction: column-reverse;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 2rem;
				}
			}

			.contact {
				margin-top: 3.3rem;

				.addresses {
					padding: 0;
					margin: 0;
					list-style: none;
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					flex-wrap: wrap;
					gap: 8.4rem;
					row-gap: 2rem;

					p {
						margin: 0;
						line-height: 1.5em;
						font-weight: 300;
					}
				}

				.btn-primary {
					margin-top: 2rem;
				}
			}

			.btn-primary,
			#languages-switcher .current {
				background-color: transparent;
				position: relative;
				z-index: 1;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: $color-primary;
					opacity: .4;
					z-index: -1;
					transition: all .2s linear;
				}

				&:hover {
					&::before {
						background-color: $color-blue-light;
					}
				}
			}
		}

		.bottom-part {
			margin-top: 5.8rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 4rem;
			position: relative;

			.insurances {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-wrap: wrap;
				gap: 1.5rem;

				.insurance {
					height: 3rem;
					width: auto;

					img {
						width: auto;
						height: 100%;
					}
				}

				@media (max-width:767px) {
					justify-content: flex-start;
				}
			}

			.socials {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: $xsmall;

				.social {
					background-color: $color-primary;

					&:hover {
						background-color: $color-blue-light;
					}
				}
			}

			@media (max-width:767px) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 2rem;
			}
		}
	}

	#footer-bottom {
		color: $color-blue-dark;
		font-size: $xsmall;
		line-height: normal;
		padding-bottom: $xsmall;
		padding-top: 1.7rem;
		padding-inline: 3.4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 2rem;

		.copyright {
			display: flex;
			align-items: center;
			gap: .5rem;

			.demathieu {
				width: 100%;
				max-width: 6rem;
				margin-right: .5rem;

				img {
					width: 100%;
				}
			}
		}

		@media (max-width:767px) {
			padding-inline: 2rem;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 1rem;

			.copyright {
				flex-wrap: wrap;
				align-items: flex-start;

				.demathieu {
					max-width: 100%;

					img {
						max-width: 6rem;
					}
				}
			}
		}
	}
}


.powered {
	position: relative;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		&:after {
			width: 100px;
		}
	}
	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;
		#logo-alt-footer-trois {
			fill: $color-blue-dark;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-deux {
			fill: $color-blue-dark;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-un {
			fill: $color-blue-dark;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}
