@import "../../assets/styles/scss/variables";

.news-bloc {
  background-color: white;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  border-radius: $rounded;
  position: relative;

  &::after {
    content: "\f054";
    font-family: $fontawesome;
    font-size: 1.1rem;
    color: $color-blue-medium;
    padding: .3rem .5rem;
    background-color: white;
    border-radius: 1px;
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    transition: all .2s linear;
}

  .img-container {
    border-radius: 0;
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 70%;

    img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .content {
    padding: 1.5rem 2rem;
    display: grid;
    grid-template-columns: 1fr auto;

    h3 {
      width: 85%;
      font-weight: bold;
      grid-column: span 2;
      margin-top: .5rem;

      @media (max-width:767px) {
        width: 100%;
      }
    }
  }

  .date {
    color: $color-orange;
    margin: 0;
    align-self: center;
  }

  .tag {
    align-self: center;
    margin-left: auto;
  }

  .excerpt {
    font-weight: 400;
    margin: 0;
    margin-top: 1rem;
    grid-column: span 2;
  }

  &:hover {
    color: $color-blue-light;

    &::after {
      right: 1rem;
    }

    .img-container {

      img {
        transform: scale(1.02);
      }
    }
  }
}