@import '../../assets/styles/scss/variables';

ul.socials {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: $xsmall !important;

  .social {
    font-size: 1.4rem;
    position: relative;
    border-radius: 50%;
    background-color: $color-blue-night;
    cursor: pointer;
    
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      min-height: 2.5rem;
    }

    i {
      color: white;
    }

    &:hover {
      background-color: $color-blue-medium;

      i {
        color: white;
      }
    }
  }
}