@import "../../assets/styles/scss/variables";

#cta-footer {
  background-color: $color-gray-light;

  .wrapper {
    padding: 7.5rem 5rem;

    @media (max-width:991px) {
      padding: 5rem 0;
    }
  }

  p.big {
    max-width: 41%;
    font-weight: 300;
    margin-top: 2.2rem;

    @media (max-width:1199px) {
      max-width: 50%;
    }

    @media (max-width:767px) {
      max-width: 80%;
    }

    @media (max-width:576px) {
      max-width: 100%;
    }
  }

  .btn-primary:not([type=submit]), .form-wrapper {
    margin-top: 2.2rem;
  }

  .form-wrapper {
    max-width: 45%;

    @media (max-width:991px) {
      max-width: 75%;
    }

    @media (max-width:576px) {
      max-width: 100%;
    }

    form {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1rem;

      .gform_body {

        input {
          padding-bottom: 1.13rem;
        }
      }

      .gform_footer {
        margin-top: 0;
      }

      @media (max-width:576px) {
       display: flex;
       flex-direction: column;
      }
    }
  }
}