@import "../../assets/styles/scss/variables";

#page-header.standard {

  @media (max-width:991px) {
    margin-top: 107px;
    padding-top: $large;
  }

  @media (max-width:767px) {
    margin-top: 97px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 9rem;
    align-items: flex-start;

    @media (max-width:1199px) {
      gap: 4.5rem;
    }

    @media (max-width:991px) {
      gap: 2rem;
    }

    @media (max-width:767px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 3rem;
    }
  }

  .left {
    margin-top: calc(107px + 4.5rem);

    @media (max-width:767px) {
      margin-top: 0;
    }
  }

  h2 {
    margin-top: $large;
  }

  .intro {
    margin-top: 2rem;
  }

  h2, .intro {
    font-weight: 300;
  }

  .img-container {
    overflow: visible;
    border-bottom-left-radius: $rounded;
    display: flex;
    position: relative;

    span {
      color: $color-blue-lighter;
      font-size: 13rem;
      line-height: 1;
      font-weight: bold;
      position: absolute;
      bottom: -42px;
      left: -4rem;

      @media (max-width:1199px) {
        font-size: 10rem;
        bottom: -32px;
        left: -3rem;
      }

      @media (max-width:767px) {
        left: -8px;
        bottom: -29px;
      }

      @media (max-width:576px) {
        display: none;
      }
    }

    @media (max-width:991px) {
      border-top-left-radius: $rounded;
    }

    @media (max-width:576px) {
      border-radius: $rounded;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}