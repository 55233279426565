@import "../../assets/styles/scss/variables";

#page-header.service {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(1, 64, 108, 0.00) 61.2%, rgba(1, 64, 108, 0.00) 69.24%, rgba(1, 64, 108, 0.75) 99.97%);
  }

  h1 {
    color: white;
    max-width: 75%;

    @media (max-width:1199px) {
      max-width: 100%;
    }
  }

  .container {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .inner-container {
    position: absolute;
    height: 100%;
    left: 50%;
    bottom: 3.9rem;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-end;
  }

  .img-container {
    border-radius: 0;
    width: 100%;
    height: 85vh;
    position: relative;
    z-index: -1;

    @media (max-width:991px) {
      height: 65vh;
    }
  }
}