@import "../../assets/styles/scss/variables";

#cta-txt {
  margin: 4.5rem 0;

  .grid {
    display: grid;
    gap: 6rem;
    grid-template-columns: 39% 39%;

    @media (max-width:1441px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 83%;
    }

    @media (max-width:767px) {
        max-width: 100%;
    }
  }
}
