//
// Template Styles
// --------------------------------------------------

#main {
    z-index: 15;
}

.services {
    position: relative;
    margin-top: 6rem;

    &::after {
        content: "";
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 21rem;
        background-color: $color-blue-lighter;
        left: 0;
        bottom: -6rem;
    }

    .inner-container {
        margin-inline: auto;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            .service-bloc {
                &:first-child {
                    grid-column: span 2;
                }
            }
        }

        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
        }
    }
}

.shortcut-bloc {
    overflow: hidden;
    border-radius: $rounded;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2rem;
    color: white;

    &.readmore-bloc {
        background-color: transparent;
        display: flex;
        flex-direction: column;

        .btn-readmore {
            background-color: white;
            color: $color-primary;

            &:hover {
                color: $color-primary;

                i {
                    color: inherit;
                }
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-blue-medium;
        z-index: -1;
        transition: all .2s linear;
    }

    &::after {
        content: "\f054";
        font-family: $fontawesome;
        font-size: 1.1rem;
        color: $color-blue-medium;
        padding: .3rem .5rem;
        background-color: white;
        border-radius: 1px;
        margin-left: auto;
        margin-top: .2rem;
    }

    .subtitle {
        margin-bottom: 1.5rem;
        font-weight: 300;

        &::before {
            display: none;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left top;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        opacity: .4;
        transition: all .2s linear;
    }

    &:hover {
        color: white;

        &::before {
            background-color: $color-blue-light;
        }

        img {
            transform: scale(1.1);
            object-position: center;
        }
    }
}

.news {

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.1rem;
    }
}

.heading-bloc {
    border-radius: $rounded;
    overflow: hidden;
    height: auto;
    color: $color-primary;
    padding-top: 2.2rem;
    padding-inline: 2.4rem;
    padding-bottom: 2.5rem;
    height: fit-content;
    background-color: $color-blue-lighter;
    transition: all .2s linear;

    &:hover {
        background-color: darken($color-blue-lighter, 5%);
    }

    @media (max-width:576px) {
        padding: 1.5rem;
        width: 100% !important;
    }

    .top {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.8rem;

        @media (max-width:576px) {
            flex-direction: column;
        }
    }

    .img-container {
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
        min-width: 3.5rem;
        margin-right: 1.2rem;

        @media (max-width:576px) {
            margin-right: 0;
            margin-bottom: 1.5rem;
        }

        img {
            object-position: top;
        }
    }

    p.xsmall {
        margin-bottom: .5rem;
        text-transform: uppercase;
    }

    .btn-secondary {
        margin-top: 1.8rem;
        color: $color-primary;
    }
}

.tabs {

    .tab-titles {
        margin-bottom: $big;
        border-bottom: 1px solid;
        display: flex;
        gap: 1rem;
        font-weight: 300;
        padding-bottom: .5rem;

        .tab-title {
            position: relative;
            cursor: pointer;
            font-weight: 300;

            &.is-active {
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: calc(-.5rem - 2px);
                    width: 100%;
                    height: 3px;
                    background-color: white;
                }
            }
        }
    }

    .tab {
        display: none;

        &.is-active {
            display: block;
        }

        h3.xsmall {
            font-weight: bold;
            margin-bottom: .5rem;
        }

        .grid {
            display: flex;
            gap: $large;

            @media (max-width:1199px) {
                flex-wrap: wrap;
            }

            @media (max-width:767px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

#page-references {
    background-color: $color-gray-light;
    padding-top: calc(122px + 5rem);
    padding-bottom: 3rem;

    #map-wrapper {
        background-color: $color-blue-dark;
        height: 100%;
        max-height: 85vh;
        width: 65%;
        border-radius: $rounded;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: -100%;
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        gap: $xsmall;

        .filters-wrapper {
            display: none;
            visibility: hidden;
        }

        .btn-open-map {
            background-color: $color-blue-medium;
            color: white;
            flex-direction: column;
            gap: .5rem;
            font-family: $font-base;
            height: auto;
            width: auto;
            text-align: center;
            font-size: .5rem;
            text-transform: uppercase;
            padding: $xsmall 1rem;
            margin-left: auto;
            cursor: pointer;

            &:hover {
                background-color: $color-blue-dark;
            }

            svg {
                width: $medium;
                min-width: $medium;
                height: auto;
            }
        }

        .searchbar-wrapper {
            display: flex;
            align-items: stretch;

            .btn-open-searchbar {
                padding: 1rem;
                background-color: white;
                cursor: pointer;
                width: auto;
                height: auto;

                svg {
                    width: 2rem;
                    min-width: 2rem;
                    height: auto;
                }
            }

            #searchbar-group {
                display: flex;
                align-items: stretch;
                width: 0;
                overflow: hidden;
                transition: all .2s linear;

                &.is-open {
                    width: calc(100% - 78px);
                }

                input {
                    background-color: white;
                    border: none;
                    font-size: 1rem;
                    color: $color-primary;
                    outline: none;
                    width: 100%;

                    &::placeholder {
                        color: inherit;
                        opacity: .5;
                    }
                }

                button {
                    color: white;
                    font-size: 1rem;
                    cursor: pointer;
                }
            }
        }
    }

    .references {
        margin-top: 3.3rem;

        &.smaller {

            .grid {
                display: flex;
                flex-direction: column;
                width: 35%;
                max-height: 85vh;
                overflow-y: auto;
                padding-right: 1rem;

                &::-webkit-scrollbar {
                    width: .55rem;
                    //display: none;
                }

                /* background */
                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                /* Poignée (thumb) */
                &::-webkit-scrollbar-thumb {
                    background: $color-blue-light;
                    transition: all .2s linear;
                    border-radius: 1.5rem;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $color-primary;
                    //Firefox + Edge
                    scrollbar-color: transparent $color-primary;
                }
            }

            .reference-bloc {
                min-height: 20rem !important;
            }

            #map-wrapper {
                right: -4vw;
            }
        }

        .grid {
            display: grid;
            column-gap: 1rem;
            row-gap: 2rem;
            grid-template-columns: repeat(3, 1fr);

            .reference-bloc {
                min-height: 29rem;

                &:nth-of-type(4n),
                &:nth-of-type(10n) {
                    grid-column: span 2;
                }

                .tag.year {
                    display: none;
                }
            }

            .marker {
                display: none;
            }

            @media (max-width:1441px) {
                grid-template-columns: repeat(2, 1fr);

                .reference-bloc {
                    grid-column: auto !important;

                    &:nth-child(3n) {
                        grid-column: span 2 !important;
                    }
                }
            }

            @media (max-width:1199px) {

                .reference-bloc {
                    min-height: 25rem !important;
                }
            }

            @media (max-width:991px) {

                .reference-bloc {
                    min-height: 20rem !important;
                }
            }

            @media (max-width:767px) {
                display: flex !important;
                flex-direction: column;
            }
        }
    }

    @media (max-width:1441px) {

        #map-wrapper {
            width: 50%;
        }

        .references.smaller {

            .grid {
                width: 50%;
            }
        }
    }

    @media (max-width:1199px) {

        #map-wrapper {
            width: 60%;
        }

        .references.smaller {

            .grid {
                width: 40%;

                .reference-bloc {
                    min-height: 20rem !important;
                }
            }
        }
    }

    @media (max-width:991px) {

        #map-wrapper {
            display: none;
            visibility: hidden;
        }

        .filters {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .filter-select {
                .ts-control {
                    width: 100%;
                    height: 100%;
                }
            }

            .searchbar-wrapper {
                grid-column: span 4;
            }

            .btn-open-map {
                display: none;
            }
        }

        .references.smaller {

            .grid {
                width: 100%;
                max-height: fit-content;
                display: grid;
            }
        }
    }

    @media (max-width:767px) {

        .filters {
            grid-template-columns: repeat(2, 1fr);

            .searchbar-wrapper {
                grid-column: span 2;
            }
        }
    }

    @media (max-width:576px) {

        .filters {
            display: block;

            .filters-wrapper {
                display: block;
                visibility: visible;
                margin-bottom: $xsmall;
            }

            .filter-select:not(.mobile) {
                display: none;
                visibility: hidden;
            }

            .bottom {
                margin-bottom: 1rem;
            }
        }
    }
}

#single-reference {

    #intro {
        display: grid;
        grid-template-columns: 58% 30%;
        gap: 7rem;
        margin-bottom: 7.5rem;

        p.big {
            width: 65%;
            font-weight: 300;
            margin-top: 2.2rem;

            @media (max-width:767px) {
                width: 100%;
            }
        }

        @media (max-width:1441px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 4rem;
        }

        @media (max-width:1199px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width:767px) {
            margin-bottom: 4rem;
        }
    }

    #reference-content {

        .img-small-section {
            margin-top: 10rem;

            @media (max-width:991px) {
                margin-top: 5rem;
            }

            .img-container {
                width: 54%;
                margin-left: auto;

                @media (max-width:767px) {
                    width: 67%;
                }
            }

            .grid {
                display: grid;
                gap: 6rem;
                grid-template-columns: 39% 39%;
                margin-bottom: $huge;

                @media (max-width:1441px) {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    max-width: 83%;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                }
            }

            .img-container {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .img-big-section {
            margin-top: 10rem;

            @media (max-width:991px) {
                margin-top: 5rem;
            }

            .container-fluid {
                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 48%;
                    background-color: $color-blue-lighter;
                }
            }

            .img-container {
                width: 80%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .grid {
                display: grid;
                gap: 6rem;
                grid-template-columns: 39% 39%;
                margin-bottom: $huge;

                @media (max-width:1441px) {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    max-width: 83%;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                }
            }
        }

        .txt-section {
            margin-top: 4.5rem;

            .grid {
                display: grid;
                gap: 6rem;
                grid-template-columns: 39% 39%;

                @media (max-width:1441px) {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    max-width: 83%;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                }
            }
        }

        .images-section {
            margin-top: 10rem;
            margin-bottom: 12rem;

            @media (max-width:991px) {
                margin-top: 6rem;
                margin-bottom: 8rem;
            }

            .container-fluid {
                position: relative;

                &::after {
                    content: "";
                    background-color: $color-blue-lighter;
                    width: 100%;
                    height: 45%;
                    position: absolute;
                    left: 0;
                    bottom: -4.5rem;
                    z-index: -1;
                }
            }

            .swiper-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 1rem;
                margin-bottom: 1rem;
            }

            .images-slider {
                .swiper-slide {
                    width: 60% !important;
                    padding-top: 40vw;
                    position: relative;

                    &::nth-child(2n) {
                        width: 30% !important;
                    }

                    &:nth-child(3n) {
                        width: 60% !important;
                    }

                    @media (max-width:991px) {
                        width: 70% !important;

                        &::nth-child(2n) {
                            width: 20% !important;
                        }

                        &:nth-child(3n) {
                            width: 70% !important;
                        }
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .copyright {
                font-style: italic;
                margin-top: 1.2rem;
                font-size: .7rem;
                line-height: normal;
                text-align: right;

                p {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }
    }
}

#related-references {
    margin-top: 7.8rem;

    @media (max-width:991px) {
        margin-top: 4rem;
    }

    .container-fluid {
        padding: 7.7rem 0;
        background-color: $color-gray-light;

        @media (max-width:991px) {
            padding: 4rem 0;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 7fr 5fr;
        gap: 1rem;

        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
        }
    }

    .reference-bloc {
        min-height: 29rem;

        @media (max-width:1199px) {
            min-height: 25rem;
        }

        @media (max-width:991px) {
            min-height: 20rem;
        }
    }
}

#related-news {

    .container-fluid {
        padding: 7.7rem 0;
        background-color: $color-gray-light;

        @media (max-width:991px) {
            padding: 4rem 0;
        }
    }

    .grid {
        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
        }
    }
}

#single-news {

    #post-content {
        margin-bottom: 10rem;
        max-width: 80%;
        margin-inline: auto;

        @media (max-width:1199px) {
            max-width: 100%;
        }

        @media (max-width:991px) {
            margin-bottom: 5rem;
        }
    }
}

#page-blog {
    background-color: $color-gray-light;
    padding-top: calc(122px + 5rem);
    padding-bottom: 7rem;

    .filters {

        .bottom {
            margin-bottom: $xsmall;
        }
    }

    .news {
        margin-top: 3.3rem;

        .grid {
            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

#page-team {
    margin-top: 7rem;

    @media (max-width:991px) {
        margin-top: 4rem;
    }

    .container-fluid {
        background-color: $color-gray-light;
        padding-bottom: 7rem;

        @media (max-width:991px) {
            padding-bottom: 4rem;
        }
    }

    .category-section {
        padding-top: 7rem;
        display: grid;
        grid-template-columns: 3fr 9fr;
        gap: 1rem;
        align-items: flex-start;

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
            gap: 0;
            padding-top: 4rem;
        }
    }

    .team {

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.5rem;

            @media (min-width:1920px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}


#page-blocs {
    margin-top: 10rem;
    margin-bottom: 10rem;

    @media (max-width:991px) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    #blocs-slide {
        margin-bottom: 5rem;

        .inner-container {
            flex-wrap: wrap;
        }
    }

    .bloc-img {
        margin-bottom: 9rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width:1199px) {
            display: flex;
            flex-direction: column;
        }

        .inner-container {
            grid-column: span 2;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .descr {
                padding: 4rem 2rem;
                padding-bottom: 0;
            }

            @media (max-width:1199px) {
                display: flex;
                flex-direction: column;

                .descr {
                    padding-inline: 0;
                }
            }
        }

        .img-container {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;

            @media (max-width:1199px) {
                border-radius: 0;
                border-top-left-radius: $rounded;
                border-top-right-radius: $rounded;
            }
        }

        .title {
            padding: 6.8rem 4.9rem;
            padding-left: 7.5rem;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-top-right-radius: $rounded;
            border-bottom-right-radius: $rounded;
            position: relative;

            img.bloc-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: bottom right;
                z-index: -1;
            }

            &.bg-green {
                background-color: $color-green-dark;

                img.bloc-bg {
                    display: none;
                }
            }

            @media (max-width:1199px) {
                padding: 4rem 2rem;
                border-radius: 0;
                border-bottom-left-radius: $rounded;
                border-bottom-right-radius: $rounded;
            }
        }

        @media (max-width:991px) {
            margin-bottom: 4rem;
        }
    }
}

#page-cta {
    margin-top: 4rem;

    .img-bloc-section {
        margin-bottom: 7rem;

        .container-fluid {

            .inner-container {
                margin-left: 0;

                @media (max-width:991px) {
                    margin-inline: auto;
                }
            }
        }

        .bloc-img {
            display: grid;
            grid-template-columns: auto 33%;
            gap: 8%;
            align-items: center;

            .img-container {
                width: 100%;
            }

            .content {

                h3 {
                    font-weight: 400;
                    margin-bottom: 2.2rem;
                }
            }

            @media (max-width:1441px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 4%;
            }

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                align-items: flex-start;
            }
        }

        // &:nth-of-type(odd) {

        //     .container-fluid {

        //         .inner-container {
        //             margin-left: auto;
        //         }
        //     }

        //     .bloc-img {
        //         grid-template-columns: 33% auto;

        //         .content {
        //             order: 1;
        //         }

        //         .img-container {
        //             order: 2;
        //         }
        //     }
        // }
    }

    .blocs-txt {
        margin-bottom: 6rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;

            &:hover {

                .bloc-txt {
                    background-color: $color-gray-light;

                    &:hover {
                        background-color: $color-blue-lighter;

                        .tag {
                            background-color: white;
                            color: $color-blue-light;
                        }
                    }
                }
            }

            @media (max-width:1199px) {
                display: flex;
                flex-direction: column;

                .bloc-txt {
                    background-color: $color-gray-light;
                    padding: 2.5rem;
                    border-radius: 0;

                    &:first-child {
                        border-top-left-radius: $rounded;
                        border-top-right-radius: $rounded;
                    }

                    &:last-child {
                        border-bottom-left-radius: $rounded;
                        border-bottom-right-radius: $rounded;
                    }

                    &:nth-child(even) {
                        background-color: $color-blue-lighter;
                    }
                }
            }
        }

        .bloc-txt {
            background-color: $color-blue-lighter;
            padding: 4rem 6.5rem;
            padding-right: 5.5rem;
            transition: all .2s linear;

            &:nth-child(3n+1) {
                background-color: $color-gray-light;
            }

            &:nth-child(odd) {
                border-top-left-radius: $rounded;
                border-bottom-left-radius: $rounded;
            }

            &:nth-child(even) {
                border-top-right-radius: $rounded;
                border-bottom-right-radius: $rounded;
            }

            .tag {
                margin-bottom: 3.3rem;
                transition: all .2s linear;
            }

            h2.large {
                margin-bottom: 3rem;
            }
        }
    }
}

#blocs-slide {
    background-color: $color-gray-light;
    padding: 7.8rem 0;

    @media (max-width:991px) {
        padding: 3.5rem 0;
    }

    .inner-container {
        margin-inline: auto;
        display: flex;

        @media (max-width:767px) {
            flex-direction: column;
        }
    }

    p.small-title {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .titles {
        position: relative;
        z-index: 11;
        position: relative;
        z-index: 11;
        transform: none;
        bottom: initial;
        left: initial;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: .8rem;
        width: 30%;
        margin-top: 5.4rem;

        .swiper-pagination-bullet {
            order: 2;
            opacity: .7;
            font-weight: 700;
            width: fit-content;
            height: auto;
            display: block;
            border-radius: 0;
            background: transparent;
            line-height: normal;

            &.swiper-pagination-bullet-active {
                order: 1;
                opacity: 1;
            }
        }

        @media (max-width:767px) {
            margin-top: 0;
            margin-bottom: 2rem;
            width: 100%;
        }
    }

    .blocs-slider {
        width: 75%;
        min-width: 75%;
        margin-left: -7%;

        @media (max-width:767px) {
            width: 100%;
            margin-left: 0;
        }
    }

    .bloc {
        background-color: $color-gray-light;
        height: auto;

        .grid {
            display: flex;
            align-items: flex-end;
        }

        .img-container {
            width: 55%;
            display: flex;
        }

        .content {
            width: 45%;
            background-color: white;
            border-top-right-radius: $rounded;
            border-bottom-right-radius: $rounded;
            padding-top: 6.8rem;
            padding-inline: 2.5rem;
            padding-bottom: 4rem;
            position: relative;

            .swiper-buttons {
                display: flex;
                align-items: center;
                gap: .5rem;
                position: absolute;
                right: 2.5rem;
                top: 2.5rem;
            }

            .btn-primary {
                margin-top: 2rem;
            }
        }

        @media (max-width:1199px) {

            .img-container,
            .content {
                width: 50%;
            }
        }

        @media (max-width:991px) {

            .grid {
                flex-direction: column;
                align-items: flex-start;
            }

            .content {
                width: 100%;
                border-bottom-left-radius: $rounded;
                padding: 2rem;
                padding-top: 5rem;
            }
        }

        @media (max-width:767px) {

            .img-container {
                width: 100%;
            }
        }
    }
}


#page-about {

    #blocs-readmore {
        margin-top: 7rem;

        @media (max-width:991px) {
            margin-top: 4rem;
        }

        .container-fluid {
            position: relative;
            padding-top: 6rem;
            padding-bottom: 7rem;

            .cta-bg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }
        }

        .blocs {
            margin-inline: auto;

            .grid {
                display: grid;
                gap: 1.1rem;
                grid-template-columns: repeat(3, 1fr);

                @media (max-width:1441px) {
                    grid-template-columns: repeat(2, 1fr);

                    .readmore-bloc {
                        &:first-child {
                            grid-column: span 2;
                        }
                    }
                }

                @media (max-width:991px) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    #blocs-cta {
        margin-bottom: 7rem;
        margin-top: 10rem;

        @media (max-width:991px) {
            margin-top: 5rem;
            margin-bottom: 3rem;
        }

        .img-container {
            width: 80%;
            margin-bottom: -4.5rem;
        }

        .blocs {
            z-index: 1;

            .grid {
                display: grid;
                gap: 1.5rem;
                grid-template-columns: repeat(4, 1fr);

                @media (max-width:1441px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width:767px) {
                    display: flex;
                    flex-direction: column;
                }
            }

            .shortcut-bloc {

                img {
                    object-position: right bottom;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}

#page-professions {
    margin-top: 7rem;
    margin-bottom: 10rem;

    @media (max-width:991px) {
        margin-top: 4rem;
        margin-bottom: 7rem;
    }

    .professions {

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2.9rem;

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 2.5rem;
            }

            @media (max-width:767px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }

        .profession-bloc {
            overflow: hidden;
            border-radius: $rounded;
            position: relative;
            color: white;
            cursor: pointer;
            padding-top: 110%;

            svg {
                opacity: .4;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: auto;
                z-index: -1;
                transition: all .2s linear;
            }

            &::after {
                content: "\f054";
                font-family: $fontawesome;
                font-size: 1.1rem;
                color: $color-blue-medium;
                padding: .3rem .5rem;
                background-color: white;
                border-radius: 1px;
                position: absolute;
                right: 2rem;
                bottom: 3rem;

                @media (max-width:767px) {
                    bottom: 2rem;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $color-primary;
                opacity: 0;
                transition: all .2s linear;
            }

            .tag {
                position: absolute;
                right: 2rem;
                top: 2rem;
                z-index: -1;
            }

            .img-container {
                border-radius: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            h2 {
                font-weight: 300;
                max-width: 12.5rem;
            }

            p.small {
                display: none;
            }

            .content {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-direction: column;
                gap: 1.3rem;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 3rem;
                padding-right: 4rem;
                padding-top: 2rem;

                @media (max-width:767px) {
                    padding: 2rem;
                }
            }

            &:hover {

                &::before {
                    opacity: .8;
                }

                p.small {
                    display: initial;
                    overflow-y: auto;
                }

                .content {
                    justify-content: flex-start;
                }
            }

            @media (max-width:767px) {
                padding: 0;

                &::before {
                    z-index: -1;
                    opacity: 1;
                }

                svg {
                    display: none;
                }

                p.small {
                    display: initial;
                    overflow-y: auto;
                }

                .img-container {
                    position: relative;
                    height: auto;
                    padding-top: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .content {
                    position: static;
                }
            }
        }
    }
}

#page-jobs {

    .container-fluid {

        .inner-container {

            &.intro {
                @media (max-width:1199px) {
                    margin-inline: auto;
                }
            }

            .top-part {
                display: grid;
                grid-template-columns: 58% 30%;
                justify-content: space-between;
                margin-bottom: 9rem;
                margin-top: 8rem;

                @media (max-width:1441px) {
                    grid-template-columns: 50% 40%;
                }

                @media (max-width:1199px) {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                }

                @media (max-width:991px) {
                    margin-bottom: 4rem;
                    margin-top: 4rem;
                }
            }

            .bloc-blue {
                background-color: $color-blue-lighter;
                padding: 4.8rem;
                padding-right: 2.5rem;
                border-top-left-radius: $rounded;
                border-bottom-left-radius: $rounded;

                .tag {
                    margin-bottom: 3.3rem;
                }

                h2.large {
                    margin-bottom: 3rem;
                }

                .btn-primary {
                    margin-top: 7.8rem;
                }

                @media (max-width:1199px) {
                    padding: 2.5rem;
                    border-radius: $rounded;

                    h2.large {
                        margin-bottom: 1.5rem;
                    }

                    .btn-primary {
                        margin-top: 5rem;
                    }
                }
            }
        }
    }
}

#single-service {

    #intro {
        display: grid;
        grid-template-columns: 58% 30%;
        gap: 7rem;
        margin-bottom: 7.5rem;

        .subtitle {
            margin-top: 4.7rem;

            @media (max-width:1199px) {
                margin-top: 0;
            }
        }

        p.big {
            width: 65%;
            font-weight: 300;
            margin-top: 2.2rem;

            @media (max-width:767px) {
                width: 100%;
            }
        }

        @media (max-width:1441px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 4rem;
        }

        @media (max-width:1199px) {
            display: flex;
            flex-direction: column-reverse;

            .heading-bloc {
                width: fit-content;
            }
        }
    }

    .heading-bloc {
        margin-top: -2.4rem;
        z-index: 1;
    }

    #service-content {

        >div:last-child {
            background-color: $color-gray;
        }

        .images-section {
            margin-bottom: 11rem;

            .container-fluid {
                position: relative;

                &::after {
                    content: "";
                    background-color: $color-blue-lighter;
                    width: 100%;
                    height: 45%;
                    position: absolute;
                    left: 0;
                    bottom: -4.5rem;
                    z-index: -1;
                }
            }

            .swiper-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 1rem;
                margin-bottom: 1rem;
            }

            .images-slider {
                .swiper-slide {
                    width: 60% !important;
                    padding-top: 40vw;
                    position: relative;

                    &::nth-child(2n) {
                        width: 30% !important;
                    }

                    &:nth-child(3n) {
                        width: 60% !important;
                    }

                    @media (max-width:991px) {
                        width: 70% !important;

                        &::nth-child(2n) {
                            width: 20% !important;
                        }

                        &:nth-child(3n) {
                            width: 70% !important;
                        }
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .txt-section {
            margin-bottom: 6.5rem;
            padding: 5.9rem 0;

            @media (max-width:991px) {
                padding: 5rem 0;
            }

            @media (max-width:767px) {
                margin-bottom: 3rem;
            }

            .tabs {
                background-color: white;
                border-radius: $rounded;
                padding: 5.3rem 4.2rem;
                display: grid;
                grid-template-columns: auto 50%;
                gap: 1rem;
                justify-content: space-between;

                @media (max-width:1441px) {
                    grid-template-columns: auto 55%;
                }

                @media (max-width:1199px) {
                    display: flex;
                    flex-direction: column;
                    padding: 3rem 2rem;
                }

                .tab-titles {
                    flex-direction: column;
                    margin-bottom: 0;
                    padding: 0;
                    border: 0;

                    @media (max-width:1199px) {
                        flex-direction: row;
                        overflow-x: auto;
                        overflow-y: hidden;
                        padding: .5rem 0;
                        gap: 1.2rem;
                    }

                    .tab-title {
                        white-space: nowrap;
                        font-size: $big !important;
                        line-height: 129% !important;
                        order: unset !important;
                    }
                }

                .tab-title {
                    font-family: $font-title;
                    font-weight: 700;
                    opacity: .7;
                    order: 2;

                    &.is-active,
                    &:hover {
                        opacity: 1;
                    }

                    &.is-active {
                        font-size: clamp(2rem, 3.65vw, $huge);
                        font-weight: bold;
                        line-height: normal;
                        order: 1;
                    }
                }

                .tab {

                    p,
                    li,
                    a {
                        font-size: inherit;
                        line-height: inherit;
                    }
                }
            }
        }

        .references-section {
            margin-bottom: 7rem;
            padding: 6.5rem 0;

            .references-slider {

                .swiper-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    margin-bottom: 1rem;
                }

                .reference-bloc {
                    min-height: 29rem;

                    @media (max-width:1199px) {
                        min-height: 25rem;
                    }

                    @media (max-width:991px) {
                        min-height: 20rem;
                    }
                }
            }
        }

        .video-section {
            text-align: center;
            padding: 5.9rem 0;
            margin-top: -6.5rem;
            margin-bottom: 0;
            .video-title {
                margin-bottom: 40px;
            }
            video {
                max-width: 100%;
                width: 100%;
            }

            @media (max-width:991px) {
                .video-title {
                    margin-bottom: 20px;
                }
            }
        }

        .interviews-section {
            padding: 6.5rem 0 0;
            .interviews-slider {
                padding-bottom: 6.5rem;
            }
        }

        .news-section {
            margin-bottom: 7rem;
            padding: 6.5rem 0;

            .news {

                .grid {
                    @media (max-width:1199px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media (max-width:991px) {
                        display: flex;
                        flex-direction: column;

                        .news-bloc {

                            .img-container {
                                padding-top: 50%;
                            }
                        }
                    }
                }
            }
        }

        .column-section {
            .flex {
                padding: 4.5rem;
                width: calc(2* 39% + 6rem);
            }

            .grid {
                padding: 4.5rem;
                display: grid;
                gap: 6rem;
                grid-template-columns: 39% 39%;

                @media (max-width:1441px) {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    max-width: 83%;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                }
            }
        }
    }
}

#page-services {
    margin-bottom: 12rem;
}

#page-homepage {

    #commitment {
        background-color: $color-gray-light;
        padding-top: 7rem;
        padding-bottom: 5rem;
        color: $color-green-dark;

        .inner-container {
            display: grid;
            grid-template-columns: 50% 41%;
            align-items: flex-start;

            @media (max-width:1441px) {
                grid-template-columns: 50% 50%;
            }

            @media (max-width:1024px) {
                display: flex;
                flex-direction: column;
            }
        }

        .content {
            padding-left: 8rem;

            @media (max-width:1441px) {
                padding-left: 4rem;
            }

            @media (max-width:1024px) {
                display: flex;
                flex-direction: column-reverse;
                padding: 0;
            }
        }


        h3 {
            font-weight: 300;
            margin-top: $big;
        }

        h2 {
            margin-top: 1rem;
            font-weight: 400;
        }

        .btn-primary {
            margin-top: 2rem;
        }

        .numbers {
            margin-top: 6rem;
            margin-left: -12rem;
            color: white;
            background-color: $color-green-dark;
            padding: $large $huge;
            border-radius: $rounded;

            .grid {
                display: flex;
                gap: $huge;
            }

            .number {
                text-align: center;
                font-weight: bold;
                font-size: 3rem;
                line-height: 129%;
            }

            @media (max-width:1441px) {
                padding-inline: $massive;
            }

            @media (max-width:1024px) {
                margin: 0;
                margin-top: -4rem;

                .grid {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: $massive;
                }
            }

            @media (max-width:767px) {

                .grid {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    #references {
        padding-bottom: 6.5rem;

        @media (max-width:767px) {
            padding-bottom: 4rem;
        }

        h2.subtitle {
            margin-bottom: 0;
            align-self: center;
        }

        .container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            @media (max-width:576px) {
                flex-direction: column;
                justify-content: flex-start;

                h2.subtitle {
                    margin-bottom: 2rem;
                    align-self: flex-start;
                }

                .btn-primary {
                    margin-left: auto;
                    width: 100%;
                }
            }
        }

        .references {
            width: 100%;
            margin-top: 2.8rem;

            .grid {
                display: grid;
                gap: 1.5rem;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 40vh 40vh;

                .reference-bloc {

                    &:first-child {
                        grid-row: 1 / 3;
                    }
                }

                @media (max-width:1199px) {

                    .reference-bloc {
                        min-height: 24rem;

                        &:first-child {
                            grid-row: auto;
                            grid-column: span 2;
                        }
                    }
                }

                @media (max-width:767px) {
                    display: flex;
                    flex-direction: column;

                    .reference-bloc {
                        min-height: 20rem;
                    }
                }
            }
        }
    }

    #intro {
        margin-bottom: 12rem;

        h2 {
            max-width: 58%;

            @media (max-width:1199px) {
                max-width: 83%;
            }

            @media (max-width:767px) {
                max-width: 100%;
            }
        }

        p.big {
            max-width: 41%;
            font-weight: 300;
            margin-top: 2rem;

            @media (max-width:1199px) {
                max-width: 58%;
            }

            @media (max-width:767px) {
                max-width: 83%;
            }

            @media (max-width:576px) {
                max-width: 100%;
            }
        }
    }

    #commitment {
        background-color: $color-gray-light;
        padding-top: 7rem;
        padding-bottom: 5rem;
        color: $color-green-dark;

        @media (max-width:767px) {
            padding-top: 4rem;
        }

        .inner-container {
            display: grid;
            grid-template-columns: 50% 41%;
            align-items: flex-start;

            @media (max-width:1441px) {
                grid-template-columns: 50% 50%;
            }

            @media (max-width:1024px) {
                display: flex;
                flex-direction: column;
            }
        }

        .content {
            padding-left: 8rem;

            @media (max-width:1441px) {
                padding-left: 4rem;
            }

            @media (max-width:1024px) {
                display: flex;
                flex-direction: column-reverse;
                padding: 0;
            }

            @media (max-width:767px) {
                width: 100%;
            }
        }


        h3 {
            font-weight: 300;
            margin-top: $big;
        }

        h2 {
            margin-top: 1rem;
            font-weight: 400;
        }

        .btn-primary {
            margin-top: 2rem;
        }

        .numbers {
            margin-top: 6rem;
            margin-left: -12rem;
            color: white;
            background-color: $color-green-dark;
            padding: $large $huge;
            border-radius: $rounded;

            .grid {
                display: flex;
                gap: $huge;
            }

            .number {
                text-align: center;
                font-weight: bold;
                font-size: 3rem;
                line-height: 129%;

                .unit {
                    font-size: 2rem;
                    line-height: 129%;
                }
            }

            @media (max-width:1441px) {
                padding-inline: $massive;
            }

            @media (max-width:1024px) {
                margin: 0;
                margin-top: -4rem;

                .grid {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: $massive;
                }
            }

            @media (max-width:767px) {
                width: 100%;
                padding: 2rem 1.5rem;
                border-radius: 0;

                .grid {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: center;
                }

                .number {
                    max-width: calc(50% - 1.25rem);
                }
            }
        }
    }

    #news {
        background-color: $color-gray-light;
        padding-top: 5rem;
        padding-bottom: 7rem;

        h2.subtitle {
            margin-bottom: 0;
            align-self: center;
        }

        .inner-container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;

            @media (max-width:576px) {
                flex-direction: column;
                justify-content: flex-start;

                .btn-primary {
                    margin-left: auto;
                    width: 100%;
                }

                h2.subtitle {
                    margin-bottom: 2rem;
                    align-self: flex-start;
                }
            }

            .news-slider {
                width: 100%;
                margin-top: 2.5rem;
                margin-right: -1.1rem;

                .swiper-wrapper {

                    .swiper-slide {
                        height: auto !important;

                        .news-bloc {
                            height: 100%;
                            margin-right: 1.1rem;
                        }
                    }
                }

                .swiper-buttons {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 1rem;
                    margin-right: 1.1rem;
                }
            }
        }
    }
}

#single-branch {
    margin-top: 4.5rem;
    margin-bottom: 5.8rem;

    h3.gigantic {
        max-width: 66%;

        @media (max-width:1199px) {
            max-width: 83%;
        }

        @media (max-width:991px) {
            max-width: 100%;
        }
    }

    p.big {
        font-weight: 300;
        margin-top: 3.2rem;
        max-width: 41%;

        @media (max-width:1199px) {
            max-width: 66%;
        }

        @media (max-width:991px) {
            max-width: 83%;
        }

        @media (max-width:767px) {
            max-width: 100%;
        }
    }
}

#page-contact {
    margin-bottom: 7rem;

    .container {
        background-color: $color-gray-light;
        border-radius: $rounded;

        .inner-container {
            padding: 5.5rem 0;
            display: grid;
            grid-template-columns: 41% 41%;
            gap: 1.5rem;
            justify-content: space-between;

            @media (max-width:1441px) {
                gap: 1rem;
                grid-template-columns: 45% 45%;
            }

            @media (max-width:991px) {
                width: 92vw;
            }

            @media (max-width:767px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                padding: 2rem 0;
            }

            @media (max-width:576px) {
                width: 85vw;
            }
        }

        @media (max-width:991px) {
            width: 100%;
        }
    }

    .form-wrapper {
        margin-top: 3rem;

        @media (max-width:767px) {
            margin-top: 0;
        }
    }
}

#page-standard {
    padding-top: 5rem;
    padding-bottom: 10rem;

    .content {
        width: 100%;
        max-width: calc(100% - 4rem);
        margin-inline: auto;
    }

    @media (max-width:767px) {
        padding-top: 2rem;
        padding-bottom: 5rem;

        .content {
            max-width: 100%;
        }
    }
}