@import "../../assets/styles/scss/variables";

.reference-bloc {
  overflow: hidden;
  border-radius: $rounded;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  color: white;
  max-height: 84vh;
  z-index: 1;

  @media (max-width:576px) {
    padding: 1.5rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(1, 64, 108, 0.00) 66.6%, rgba(1, 64, 108, 0.00) 73.52%, rgba(1, 64, 108, 0.75) 100%);
    z-index: -1;
    transition: all .2s linear;
  }

  .img-container {
    border-radius: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  h3 {
    position: relative;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &::after {
      content: "\f054";
      font-family: $fontawesome;
      font-size: 1.1rem;
      color: $color-blue-medium;
      padding: .3rem .5rem;
      background-color: white;
      border-radius: 1px;
      line-height: 1;
    }
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: .5rem;
  }

  &:hover {
    color: white;

    &::after {
      opacity: .4;
      background: $color-primary;
    }

    .img-container {

      img {
        transform: scale(1.02);
      }
    }
  }
}