@import "../../assets/styles/scss/variables";

.service-bloc {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 3rem;
  padding-right: 2rem;
  padding-top: 21rem;
  color: white;
  overflow: hidden;
  border-radius: $rounded;
  position: relative;

  @media (max-width:767px) {
    padding-top: 12rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-blue-medium;
    opacity: .0;
    transition: all .2s linear;
  }

  // &::after {
  //   content: "\f054";
  //   font-family: $fontawesome;
  //   font-size: 1.1rem;
  //   color: $color-blue-medium;
  //   padding: .3rem .5rem;
  //   background-color: white;
  //   border-radius: 1px;
  //   margin-left: auto;
  //   margin-bottom: -1rem;
  //   z-index: 1;
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all .2s linear;
  }

  h3 {
    font-weight: 300;
    max-width: 13rem;
    z-index: 1;
  }

  svg {
    opacity: .4;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 145%;
    height: auto;
    z-index: -1;
    transition: all .2s linear;
    
    @media (max-width:991px) {
      width: 130%;
    }

    @media (max-width:767px) {
      width: 100%;
    }

    @media (max-width:576px) {
      width: 130%;
    }
  }

  &:hover {
    color: white;

    &::before {
      opacity: .4;
    }

    svg {
      opacity: .6;
    }

    img {
      transform: scale(1.02);
    }
  }
}