@import "../../assets/styles/scss/variables";

#page-header.reference {
  margin-bottom: 7.8rem;

  @media (max-width:991px) {
    margin-bottom: 4rem;
  }

  .inner-container {
    margin-inline: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    bottom: 0;
    
    @media (max-width:1199px) {
      position: relative;
      padding-top: calc(110px + 6.5rem);
      transform: none;
      left: initial;
    }
  }

  .infos {
    color: white;
    padding-bottom: 4.3rem;

    @media (max-width:1199px) {
      width: 100%;
    }

    .tags {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      @media (max-width:767px) {
        gap: .3rem;
      }
    }

    h1 {
      max-width: 80%;
    }

    .tabs {
      margin-top: 3rem;

      .swiper-buttons {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        gap: .5rem;
        margin-top: 2rem;

        .swiper-button-next, .swiper-button-prev {
          color: white;
          background-color: transparent;
          border: 1px solid white;
        }
      }
    }
  }

  .img-container {
    border-radius: 0;
    position: relative;
    display: flex;

    @media (max-width:1199px) {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &::after {
      content: "";
      background: linear-gradient(180deg,rgba(1,64,108,0) 25%,rgba(1,64,108,0) 55%,rgba(1,64,108,.7) 85%);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}