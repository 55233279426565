@import "../../assets/styles/scss/variables";

#page-header.branch {
  margin-top: calc(107px + 9rem);
  margin-bottom: 10rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 13rem;
    background-color: $color-blue-lighter;
    bottom: -4rem;
    left: 0;
  }

  .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 6.5rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: calc(100% - 6.5rem);
    
    p.type {
      margin-top: 2.4rem;
      font-size: 1.8rem;
      line-height: normal;
    }

    h2 {
      margin-top: 1.1rem;
    }

    address {
      margin-top: 2.2rem;
      margin-bottom: 1.1rem;
    }
  }

  .img-container {
    border-radius: $rounded;
    overflow: hidden;
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width:1441px) {
    
    .grid {
      gap: 3rem;
    }

    .right {
      width: calc(100% - 3rem);
    }
  }

  @media (max-width:991px) {
    margin-top: calc(107px + 2rem);

    .grid {
      display: flex;
      flex-direction: column;
    }

    .right {
      width: 100%;
      gap: 3rem;

      p.type {
        margin-top: 0;
      }
    }
  }

  @media (max-width:576px) {

    .branches-slider {
      width: 92vw;
    }

    .branch-bloc {
      width: 75%;
    }
  }
}