//
// Font Faces Styles
// @help: url('../webfonts/xxx)
// --------------------------------------------------


//Replica Pro
@font-face {
  font-family: 'Replica Pro';
  src: url('../webfonts/replica-pro/ReplicaPro-Regular.woff2') format('woff2'),
      url('../webfonts/replica-pro/ReplicaPro-Regular.woff') format('woff'),
      url('../webfonts/replica-pro/ReplicaPro-Regular.svg#ReplicaPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Replica Pro';
  src: url('../webfonts/replica-pro/ReplicaPro-Bold.woff2') format('woff2'),
      url('../webfonts/replica-pro/ReplicaPro-Bold.woff') format('woff'),
      url('../webfonts/replica-pro/ReplicaPro-Bold.svg#ReplicaPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Replica Pro';
  src: url('../webfonts/replica-pro/ReplicaPro-LightItalic.woff2') format('woff2'),
      url('../webfonts/replica-pro/ReplicaPro-LightItalic.woff') format('woff'),
      url('../webfonts/replica-pro/ReplicaPro-LightItalic.svg#ReplicaPro-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Replica Pro';
  src: url('../webfonts/replica-pro/ReplicaPro-BoldItalic.woff2') format('woff2'),
      url('../webfonts/replica-pro/ReplicaPro-BoldItalic.woff') format('woff'),
      url('../webfonts/replica-pro/ReplicaPro-BoldItalic.svg#ReplicaPro-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Replica Pro';
  src: url('../webfonts/replica-pro/ReplicaPro-Light.woff2') format('woff2'),
      url('../webfonts/replica-pro/ReplicaPro-Light.woff') format('woff'),
      url('../webfonts/replica-pro/ReplicaPro-Light.svg#ReplicaPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Replica Pro';
  src: url('../webfonts/replica-pro/ReplicaPro-Italic.woff2') format('woff2'),
      url('../webfonts/replica-pro/ReplicaPro-Italic.woff') format('woff'),
      url('../webfonts/replica-pro/ReplicaPro-Italic.svg#ReplicaPro-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}



//Font Awesome
@font-face {
  font-family: "Font Awesome 6 Brands";
  src: url("../webfonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  src: url("../webfonts/fontawesome/fa-sharp-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-sharp-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}