@import "../../assets/styles/scss/variables";

.readmore-bloc {
  background-color: white;
  border-radius: $rounded;
  overflow: hidden;
  padding: 2.5rem;
  display: grid;
  grid-template-rows: 1fr 2fr auto;
  align-items: flex-start;

  @media (max-width:991px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  h2.big {
      font-weight: bold;
      margin-bottom: 1.5rem;
  }

  .btn-readmore {
      margin-top: 1.7rem;
      cursor: pointer;

      &.not-visible {
        display: none;
        @media (max-width:991px) {
          display: none;
        }
      }
  }
}