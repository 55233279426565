@import "../../assets/styles/scss/variables";

.member-bloc {
  overflow: hidden;
  border-radius: $rounded;

  .img-container {
    display: flex;
  }

  .infos {
    background-color: white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: 100%;

    h3 {
      font-weight: 400;
    }
  }
}