//
// Header Styles
// --------------------------------------------------

#header {
	width: calc(100% - 1rem);
	background-color: white;
	box-shadow: 1px 1px 10px 0px rgba(0, 39, 66, 0.06);
	padding-top: 1.4rem;
	position: absolute;
	left: .5rem;
	top: .5rem;
	z-index: 20;
	transition: all 0.4s linear;
	border-radius: $rounded;
	overflow: hidden;

	@media (max-width:991px) {
		padding: 1.3rem 0 !important;
		width: 100vw;
		top: 0;
		left: 0;
		border-radius: 0;
	}

	&.sticky {
		#header-extras {
			opacity: 0;
			margin-top: -40px;
		}

		#logo {
			max-width: 8rem;
		}

		#menu {
			.mega-menu-container {
				top: 115px;
			}
		}
	}

	#burgermenu-trigger {
		padding: 0;
		background-color: white;
		margin-top: -35px;
		align-self: center;

		@media (max-width:991px) {
			margin-top: 0;
		}

		a {
			font-size: 2.4rem;
			color: $color-blue-night;
			
			&:hover {
				color: $color-blue-light;
			}
		}

		i.fa-xmark {
			display: none;
		}

		&.is-active {
			min-width: 42px;

			@media (max-width:767px) {
				min-width: 38px;
			}

			i.fa-bars {
				display: none;
			}

			i.fa-xmark {
				display: initial;
			}

			a {
				color: $color-blue-dark;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#logo {
		width: 100%;
		max-width: 11rem;
		margin-bottom: 1.4rem;
		transition: all .4s linear;
		a {
			display: block;
		}

		svg {
			width: 100%;
			height: auto;

			path, rect {
				fill: #14213E;
			}
		}

		@media (max-width:991px) {
			max-width: 9rem !important;
			margin: 0 !important;
		}
	}

	#menu {
		transition: all 0.4s linear;
		align-self: flex-end;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		gap: 4.5rem;

		#menu-main-container {
			display: inline-block;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 4.3rem;
				li.first-level {
					position: relative;
					a {
						font-size:$medium;
						display: block;
						color: $color-blue-night;
						padding-bottom: 2rem;
						font-weight: 400;
						position: relative;
						&::after {
							content: "";
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 5px;
							background-color: white;
						}

						&:hover {
							color: $color-blue-light;

							&::after {
								background-color: $color-blue-light;
							}
						}
					}

					&.is-active {

						a {
							color: $color-blue-light;

							&::after {
								background-color: $color-blue-light;
							}
						}
					}
					&.current_page_item,
					&.current_page_ancestor,
					&.current_page_parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: $color-blue-light;

							&::after {
								background-color: $color-blue-light;
							}
						}
						.sub-menu {
							li {
								a {
									color: $color-blue-medium;
								}
							}
						}
					}
				}
			}
		}

		#menu-main-container,
		#menu-secondary-container {
			@media (max-width:991px) {
				display: none !important;
				visibility: hidden;
			}
		}

		.mega-menu-container {
			position: fixed;
			left: .5rem;
			top: 131px;
			width: calc(100% - 1rem);
			background-color: white;
			border-radius: $rounded;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			overflow: hidden;
			min-height: 45vh;
			max-height: 75vh;
			overflow-y: auto;
			display: none;

			&.is-open {
				display: block;
			}

			.inner-container {
				display: grid;
				grid-template-columns: 40% 45%;
				justify-content: space-between;
				padding-top: $large;
				padding-bottom: $huge;

				@media (max-width:1199px) {
					grid-template-columns: repeat(2, 1fr);
					gap: 2rem;
				}
			}

			.second-level-container {
				flex-direction: column;
				align-items: flex-start !important;
				justify-content: flex-start !important;
				gap: 0 !important;
				text-align: left;
				padding: 0;
				margin: 0;
				list-style: none;

				li {
					border-bottom: 1px solid $color-blue-lighter;
					width: 100%;

					&:last-child {
						border-bottom: none;
					}
					a {
						background-color: white;
						padding: $big .5rem;
						display: flex;
						color: $color-blue-night;

						&:hover {
							padding: $big 1rem;
							color: $color-blue-light;
							background-color: $color-gray-light;
						}
					}
				}
			}

			.right-part {
				text-align: left;

				img {
					border-radius: $rounded;
					overflow: hidden;
				}

				address {
					margin-bottom: $large;
					color: black;

					p {
						margin: 0;
						line-height: 1.5em;
						font-weight: 300;
					}
				}
			}
		}

		#mobile-menu {
			display: none !important;
			visibility: hidden;
			position: fixed;
			width: 100%;
			height: calc(100vh - 110px);
			left: 0;
			top: 110px;
			position: fixed;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: $color-primary;
				z-index: -1;
				opacity: .4;
			}

			.container,
			.inner-container,
			#mobile-menu-container {
				height: 100%;
			}
			
			.mobile-menu-container {
				background-color: white;
				width: calc(100% - 1rem);
				height: calc(100% - 1rem);
				overflow-x: hidden;
				overflow-y: auto;
				border-radius: $rounded;
				margin-inline: auto;
				margin-top: .5rem;

				#mobile-menu-container {
					position: relative;
				}

				#header-extras {
					position: absolute;
					left: 0;
					bottom: 2vh;
					top: initial;
					right: initial;

					#languages-switcher {
						display: flex;
						visibility: visible;
					}
				}
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					width: 100%;

					&.mobile-first-level {
						border-bottom: 1px solid $color-blue-lighter;
						a {
							padding: $regular .25rem;
						}

						&:last-child {
							border-bottom: none;
						}

						&.has-children {
							position: relative;

							&.is-active {
								&::before {
									content: "";
									position: absolute;
									left: 50%;
									top: 0;
									transform: translateX(-50%);
									width: 100vw;
									height: 100%;
									background-color: $color-gray-light;
								}

								>a {
									color: $color-blue-light;

									&::after {
										transform: rotate(180deg);
									}
								}
							}

							>a {
								align-items: center;
								justify-content: space-between;
								position: relative;
								&::after {
									content: "\f078";
									font-family: $fontawesome;
									font-weight: 400;
								}
							}
						}
					}

					&.mobile-second-level {
						position: relative;
						a {
							padding: .45rem .6rem;
						}
					}

					a {
						font-weight: 400;
						display: flex;

						&:hover {
							color: $color-blue-light;
						}
					}

					ul.mobile-second-level-container {
						display: none;

						&.is-open {
							display: block;
							padding-bottom: 2rem;
						}

						li {

							a {
								font-size: 1rem;
							}
						}
					}
				}
			}

			@media (max-width:991px) {
				visibility: visible;

				&.is-open {
					display: block !important;
				}
			}

			@media (max-width:767px) {
				top: 98px;
				height: calc(100vh - 98px);
			}
		}

		@media (max-width:991px) {
			position: relative;
		}
	}

	#header-extras {
		position: absolute;
		top: 0;
		right: 0;
		transition: all 0.4s linear;
		margin-top: 0;

		#languages-switcher {

			.current {
				padding: .3rem .6rem;
				background-color: $color-blue-medium;
			}

			.languages-list {

				li {

					a {
						padding: .2rem .6rem;
						background-color: $color-blue-medium;
					}
				}
			}

			@media (max-width:991px) {
				display: none;
				visibility: hidden;
			}
		}
	}
}

body.mega-menu-open {
	position: relative;
	
	.mega-menu-overlay {
		left: 0;
		top: 0;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: black;
		opacity: .4;
		z-index: 1;
	}
}

