@import "../../assets/styles/scss/variables";

.branch-bloc {
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1rem;
  padding-top: 9rem;
  position: relative;
  overflow: hidden;
  border-radius: $rounded;
  
  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(2, 116, 165, 0.13) 61.38%, rgba(1, 64, 108, 0.83) 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .2s linear;
  }

  h3 {
    position: relative;
    z-index: 1;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    i {
      font-size: 1.1rem;
      color: $color-blue-medium;
      padding: .3rem .4rem;
      background-color: white;
      border-radius: 1px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .2s linear;
  }

  &:hover {
    color: white;
    &::after {
      background-color: $color-primary;
      opacity: .8;
    }

    img {
      transform: scale(1.02);
    }
  }
}