@import "../../assets/styles/scss/variables";

.ts-wrapper {
  
  .ts-control {
    font-size: $small;
    line-height: normal;
    font-weight: 400;
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 1rem;
    background-color: white;
    transition: all .2s linear;
    border-radius: 2px;
    padding: 1.3rem 1.2rem !important;
    padding-right: 3.2rem !important;
    position: relative;
    text-transform: uppercase;
    min-width: 3.5rem;

    &::after {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
      min-height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $color-blue-lighter;
      content: "\f107";
      font-family: $fontawesome;
      font-weight: 400;
      color: $color-primary;
      font-size: .5rem;
      position: absolute;
      top: 50%;
      right: $medium;
      transform: translateY(-50%);
      transition: all .2s linear;
    }
  }
  
  .ts-control,
  .ts-dropdown {
    color: $color-primary;
    font-size: 1rem;
    line-height: normal;
    border: 1px solid white;
    border-radius: $rounded;
    box-shadow: none;

    input {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .ts-dropdown {
    margin-top: 0;

    .option {
      padding: .8rem 1.2rem;
      text-transform: uppercase;
      font-weight: 400;
    }

    .selected {
      color: white;
      background-color: $color-blue-light;

      &.active {
        color: $color-primary;
      	background-color: $color-blue-lighter;
      }
    }

    .active {
      color: $color-primary;
      background-color: $color-blue-lighter;
    }
  }

  &.dropdown-active {

    .ts-control,
    .ts-dropdown {
      border-color: white;
    }
      
    .ts-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .ts-dropdown {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}