@import "../../assets/styles/scss/variables";


#page-header.news {
  margin-top: calc(122px + 5rem);
  margin-bottom: 4.5rem;

  @media (max-width: 767px) {
    margin-top: calc(98px + 5rem);
  }

  .img-container {
    max-width: 80%;
    margin-inline: auto;
    margin-top: 2rem;

    @media (max-width:1199px) {
      max-width: 100%;
    }
  }

  .button-wrapper {
    max-width: 80%;
    margin-inline: auto;
    margin-top: $huge;

    @media (max-width:1199px) {
      max-width: 100%;
    }
  }

  .intro {
    max-width: 50%;
    margin-left: 16%;

    @media (max-width:1199px) {
      max-width: 80%;
      margin-left: 8%;
    }

    @media (max-width:767px) {
      max-width: 100%;
      margin-left: 0;
    }
  }

  .date {
    color: $color-orange;
    font-weight: 400;
    margin-bottom: .5rem;
  }

  h1 {
    margin-bottom: 2rem;
  }
}