@import '../../assets/styles/scss/variables';

.gm-style {

  .gm-style-iw-chr, .gm-style-iw-tc::after {
    display: none;
  }

  .gm-style-iw-c {
    padding: 0 !important;
    min-width: 12rem !important;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }
}

.marker-content {
  position: relative;
  min-height: 7.5rem;
  padding: .8rem .5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: white;

  &:hover {
    color: white;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,80,135,0) 0%, rgba(0,80,135,0.8) 90%, rgba(0,80,135,0.8) 100%);

    // background-color: $color-blue-light;
    // opacity: .5;
  }

  .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 65%;
    z-index: -1;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p.medium {
    font-weight: 400;
    position: relative;
    z-index: 1;
  }
}