//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //17px
  line-height: 140%;
  font-weight: 300;
  color: $color-text;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

.unscroll {
  @media (max-width:991px) {
    overflow-y: hidden;
  }
}

address {

  p {
    margin: 0;
    line-height: 1.5em;
  }
}

.is-hidden,
.hidden {
  display: none !important;
}

.not-visible {
  visibility: hidden;
}

.bold {
  font-weight: bold !important;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: normal;
}

p,
li,
.regular {
  font-size: clamp(1rem, 1.04vw, $regular);
  line-height: 140%;
  font-weight: 300;
}

p {
  margin-bottom: 1.5rem;
  font-weight: 300;

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }

  strong {
    font-weight: bold;
  }
}

h6,
.medium {
  font-size: $medium;
  line-height: 129%;
  font-weight: 400;
}

h5,
.big {
  font-size: $big;
  line-height: 129%;
  font-weight: 400;
}

h4,
.large:not(input):not(textarea) {
  font-size: clamp(1.3rem, 2vw, $large);
  line-height: 129%;
  font-weight: 400;
}

h3,
.massive {
  font-size: clamp(1.8rem, 2.6vw, $massive);
  line-height: 129%;
  font-weight: bold;
}

h2,
.huge {
  font-size: clamp(2rem, 3.65vw, $huge);
  line-height: normal;
  font-weight: bold;
}

h1,
.gigantic,
#blocs-slide .swiper-pagination-bullet-active {
  font-size: clamp(2.5rem, 4.69vw, $gigantic);
  line-height: normal;
  font-weight: bold;
}

.big-title {
  font-size: clamp(3rem, 6.25vw, $big-title);
  line-height: normal;
  font-weight: bold;
}

.small-title,
.subtitle {
  font-size: .9rem;
  line-height: normal;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $xsmall;
  margin-bottom: $large;

  &::before {
    content: "\f178";
    font-family: $fontawesome;
    font-weight: 400;
    color: inherit;
  }
}

.img-container {
  overflow: hidden;
  border-radius: $rounded;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .2s linear;
  }
}

.bg-gray-light {
  background-color: $color-gray-light;
}

.bg-blue-lighter {
  background-color: $color-blue-lighter;
}

.bg-blue-light {
  background-color: $color-blue-light;
}

.btn-primary {
  font-size: $small;
  line-height: normal;
  font-weight: 400;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: $small;
  padding: .7rem 1.3rem;
  background-color: $color-blue-dark;
  transition: all .2s linear;
  border-radius: 2px;

  &.big {
    padding: 1.3rem 1.2rem;

    &:hover {
      padding-right: .9rem;
    }
  }

  &:hover {
    padding-right: 1rem;

    &::after {
      margin-left: .3rem;
    }
  }

  &::after {
    content: "\f054";
    font-family: $fontawesome;
    font-weight: 400;
    color: inherit;
    transition: all .2s linear;
  }

  &.blue {
    background-color: $color-blue-medium;

    &:hover {
      background-color: $color-blue-light;
    }
  }

  &.blue-light,
  &:hover {
    background-color: $color-blue-light;
    color: white;
  }
  
  &.green-dark {
    background-color: $color-green-dark;

    &:hover {
      background-color: $color-green-light;
    }
  }

  &.green-light {
    background-color: $color-green-light;

    &:hover {
      background-color: $color-green-dark;
    }
  }

  &.white {
    background-color: white;
    color: $color-blue-medium;

    &:hover {
      background-color: $color-blue-medium;
      color: white;
    }
  }

  &.small {
    padding: .7rem !important;
  }

  &.reset-all {
    text-transform: uppercase;
    &::after {
      content: "\f12d";
    }

    &:hover {
      padding-right: .7rem;

      &::after {
        margin-left: 0;
      }
    }
  }
}

.btn-filter {
  font-size: $small;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 5rem;

  &::after {
    content: "\f0b0";
  }

  &:hover {
    padding-right: 1.2rem !important;

    &::after {
      margin-left: 0;
      font-weight: bold;
    }
  }

  @media (max-width:576px) {
    width: 100%;
    min-width: auto;
  }
}

.btn-secondary {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  gap: .7rem;
  transition: all .2s linear;
  line-height: normal;

  &::after {
    content: "\f054";
    font-family: $fontawesome;
    font-weight: 400;
    font-size: inherit;
    color: inherit;
    line-height: 1;
  }

  &:hover {
    gap: 1rem;
  }
}

.btn-icon {
  font-size: $xsmall;
  font-family: $fontawesome;
  font-weight: bold;
  color: white;
  background-color: $color-blue-medium;
  border-radius: $rounded;
  padding: .3rem;
  width: 1.3rem;
  height: 1.3rem;
  min-width: 1.3rem;
  min-height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    line-height: 1;
    color: inherit;
  }

  &:hover {
    background-color: $color-blue-light;

    i {
      color: white;
    }
  }
}

.btn-readmore {
  &.is-active {
    i {
      transform: rotate(180deg);
    }
  }
}

.tag {
  font-size: .9rem;
  line-height: normal;
  font-weight: 400;
  color: white;
  padding: .45rem 1.3rem;
  background-color: $color-blue-light;
  border-radius: 2.9rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &.category {
    padding: .4rem .5rem;
    border-radius: 2rem;
    font-size: $xsmall;
  }

  @media (max-width:576px) {
    font-size: .9rem;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
  background-color: $color-blue-medium;
  width: $big;
  height: $big;
  min-width: $big;
  min-height: $big;
  overflow: hidden;
  border-radius: 50%;
  position: static;
  margin: 0;
  
  &::after {
    font-size: .8rem;
  }

  &:hover {
    background-color: $color-blue-light;
  }
}

.filters-wrapper {
  position: relative;
}

.filters-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: fit-content;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  display: none;

  @media (max-width:576px) {
    width: 100%;
  }

  &.is-open {
    display: flex;
  }

  .filter {
    color: $color-blue-medium;
    background-color: transparent;
    padding: 1rem 1.2rem;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1rem;
    transition: all .2s linear;
    width: 100%;
    justify-content: center;
    text-align: left;
    font-weight: 400;
    border-bottom: 1px solid $color-blue-lighter;

    &:hover,
    &.is-selected {
      background-color: $color-blue-lighter;
    }
  }
}

.filters {

  .bottom {
    width: 100%;
    display: none;

    @media (max-width:991px) {
      grid-column: span 4;
    }

    @media (max-width:767px) {
      grid-column: span 2;
    }
  }
}

.loader-content {
  margin-bottom: 2rem;
  display: none;
  
  .loader-wrapper {
    display: flex;
    justify-content: center;
  }

  .loader {
    display: inline-flex;
    gap: 5px;
    animation: l1-0 1s infinite;
  }
  .loader:before,
  .loader:after {
    content: "";
    width: $big;
    aspect-ratio: 1;
    box-shadow: 0 0 0 3px inset $color-primary;
    border-radius: $rounded;
    transform-origin:top right;
    animation: l1-1 1s infinite;
  }
  .loader:after {
    transform-origin:top left;
    --s:-1;
  }
}

@keyframes l1-0 {
  to {transform:translateY(100%)}
}
@keyframes l1-1 {
  to {transform:rotate(calc(var(--s,1)*90deg))}
}

#map-wrapper {
  .map-container {
    width: 100%;
    height: 100%;
  }
}

.filters-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-weight: 400 !important;
  display: none;

  &.is-open {
    display: flex;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    opacity: .4;
    z-index: -1;
  }

  .popup-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.5rem;
    cursor: pointer;
  }

  .popup-content {
    background-color: white;
    color: $color-primary;
    height: auto;
    max-height: 75vh;
    min-height: 50vh;
    overflow-y: auto;
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 1.2rem;
    padding-bottom: 2rem;
  }

  .filters-content {
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-weight: inherit;
      width: 100%;
      cursor: pointer;

      &.first-level {
        border-bottom: 1px solid $color-blue-lighter;
        span {
          display: flex;
          padding: $regular .25rem;
        }

        &:last-child {
          border-bottom: none;
        }

        &.has-children {
          position: relative;

          &.is-active {
            &::before {
              content: "";
              position: absolute;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              width: 100vw;
              height: 100%;
              background-color: $color-gray-light;
            }

            >button {
              color: $color-blue-light;

              &::after {
                transform: rotate(180deg);
              }
            }
          }

          >span {
            align-items: center;
            justify-content: space-between;
            position: relative;
            &::after {
              content: "\f078";
              font-family: $fontawesome;
              font-weight: 400;
            }
          }
        }
      }

      &.second-level {
        position: relative;
        button {
          padding: .45rem .6rem;
        }
      }

      button {
        font-weight: 400;
        display: flex;
        width: 100%;
        background-color: transparent;
        color: inherit;
        padding: 0;
        display: block;

        &:hover,
        &.is-selected {
          color: $color-blue-light;
        }

        &.is-selected {
          font-weight: bold;
        }
      }

      ul.second-level-container {
        display: none;
        position: relative;
        padding: 0;
        list-style: none;

        &.is-open {
          display: block;
          padding-bottom: 2rem;
        }

        li {

          button {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
