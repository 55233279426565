@import "../../assets/styles/scss/variables";

.accordion {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    opacity: .3;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  .accordion-title {
    padding: $large $xsmall;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $small;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      gap: .5rem;
      justify-content: flex-start;
    }

    .icon-bloc {
      width: 3rem;
      height: auto;
      display: block;

      img {
        width: 100%;
      }
    }

    &.is-open {

      i {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    display: none;
    padding: 0 $xsmall;
    padding-bottom: $large;
  }

  .icons.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $huge;
    row-gap: $large;

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;
    }
  }

  .icon-bloc {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;

    .icon-container {
      background-color: $color-blue-lighter;
      border-radius: $rounded;
      padding: .45rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.9rem;
      height: 2.9rem;
      min-width: 2.9rem;
      min-height: 2.9rem;

      img {
        max-height: 1.9rem;
        width: auto;
      }
    }

    h4.medium {
      font-weight: 400;
      margin-top: .5rem !important;

      @media (max-width:767px) {
        margin-top: 0 !important;
      }
    }

    p {
      font-weight: 300;
      margin-top: .5rem;
    }
  }
}