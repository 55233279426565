@import "../../assets/styles/scss/variables";

#page-header.home {
  position: relative;
  margin-bottom: 5rem;
  
  .container-title {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);

    @media (max-width:1199px) {
      top: 35%
    }

    @media (max-width:576px) {
      top: 28%;
    }
  }

  h3 {
    color: white;
    max-width: 66%;
    margin-inline: auto;

    @media (max-width:1440px) {
      max-width: 83%;
    }

    @media (max-width:991px) {
      max-width: 100%;
    }
  }
  .img-container {
    width: 100%;
    height: 60vh;
    margin-inline: auto;
    border-radius: 0;
    position: relative;
    overflow: visible;
    display: block;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 5rem;
      background-color: $color-blue-lighter;
      z-index: -1;
    }

    @media (max-width:767px) {
      height: 90vh;
    }
  }

  .container:not(.container-title) {
    z-index: 1;
    margin-top: -6rem;
  }

  .shortcuts {
    display: flex;
    gap: 1.5rem;
    justify-content: flex-end;

    .shortcut-bloc {
      width: calc(25% - .75rem);
      max-width: 21rem;

      @media (max-width:1199px) {
        width: calc(100% / 3 - .75rem);
      }

      @media (max-width:991px) {
        width: 100%;
        max-width: 100%;
      }
    }

    @media (max-width:1199px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}