@import "../../assets/styles/scss/variables";


.interviews-section {

    &.blue {

        .container-fluid {
            &::after {
                background-color: $color-blue-lighter;
            }
        }
    }

  .container-fluid {
      position: relative;

      &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -7rem;
          width: 100%;
          height: 97%;
          z-index: -1;
          background-color: $color-gray-light;
      }
  }

  .inner-container {
      margin-inline: auto;
  }

  .interviews-slider {
      margin-bottom: 7rem;
  }

  .swiper-buttons {
      z-index: 1;
      position: relative;
      display: grid;
      grid-template-columns: minmax(min(50%, 34rem), 34rem) minmax(0, 33%);
      gap: 8rem;

      @media (max-width:1441px) {
          gap: 4rem;
          grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width:1199px) {
          display: flex;
          flex-direction: column-reverse;
          margin-top: 2rem;
      }

      .wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
      }

    .swiper-button-prev, .swiper-button-next {
        border-radius: 0.111rem;
        width: 1.3rem;
        height: 1.3rem;
        min-width: 1.3rem;
        min-height: 1.3rem;
    }
  }

  .interview-bloc {

      .grid {
          display: grid;
          grid-template-columns: minmax(min(50%, 34rem), 34rem) minmax(0, 33%);
          gap: 8rem;
          align-items: flex-start;

          @media (max-width:1441px) {
              gap: 4rem;
              grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width:1199px) {
              display: flex;
              flex-direction: column;
              gap: 2rem;
          }
      }

      .content {
          padding-top: 9.5rem;
          padding-bottom: 1.3rem;

          @media (max-width:1199px) {
              padding-top: 0;
          }
      }

      .small-subtitle {
          margin-bottom: 1rem;
      }
      
      h3.massive {
          font-weight: 400;
      }

      .descr {
          margin-top: 1.5rem;

          p {
            font-weight: inherit;
          }
      }
  }
}